import Vue from 'vue';
import App from './App.vue';
import router from './router';
import ElementUI from 'element-ui';
import './json/data.js'
import './request.js';
import './css/font.css';
import './css/reset.css';
import './css/css.scss';
import 'element-ui/lib/theme-chalk/index.css';

Vue.use(ElementUI);

import vHeader from './components/Header.vue';
Vue.component('v-Header', vHeader)
import vFooter from './components/Footer.vue';
Vue.component('v-Footer', vFooter)

Vue.prototype.$ = require('jquery');
Vue.prototype.$Web3 = require('web3');
Vue.prototype.$apiUrl = 'https://api.thesoul.network/';

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
