// ["0x61eF8dcfB266228794676FF3DE7F383F9e33d61A","0x30847Dd27035d6f3731D668F2e818af1Ba741e3A","0xfC228a3fb6b43fC38D8b01B188A93698c6Ad2c92","0x58eeC2E8E0836525a0464d751D4Db093cD6A939f"]

window.appData = {
    stationCosmos:{
        contract:''
    },
    station:{
        contract:'0x6D96F9f32952dD01Bb061F490EA4228BB709dc50',
        badgeList:[
            {
                coverImg:'1.png',
                name:'spacefi.io Delegator Badge',
                issuer:'SpaceFi',
                issuerIcon:'spaceFi.png',
                contract:'0x1cD8149a5900A644D448a84a7b5D7a60671aB466',
                // contract:'0xC0aAfD27d8c9502C2a4fF2d96A2BD6d5dFfc40D6',
                starTime:'2022.09.26  10:00AM UTC',
                eligible:['spacefi.io Delegators'],
                info:`
                    <p>For delegators who staked in spacefi.io validator. Check your address in <a href="https://validator.spacefi.io/evmos" target="_blank">https://validator.spacefi.io/evmos</a> </p>
                    <br />
                    <br />
                    <p>Join SpaceFi Guild and Claim your delegator discord role: <a href="https://guild.xyz/spacefi" target="_blank">https://guild.xyz/spacefi</a></p>
                `
            }
        ],
        stampList:[
            {
                name:'Evmos NFT Whale Holders',
                minInfo:'NFT holders who hold more than 10 Orbital APE or 10 SpaceFi Common Planet NFT ( one Unique Planet or one Exclusive STAR Sign NFT ).',
                type:'EVM-ADDRESS',
                created:'0x0315e4702D5D7F39E4e40be7FbB3De98cD2386D0',
                info:'NFT holders who hold more than 10 Orbital APE or 10 SpaceFi Common Planet NFT ( one Unique Planet or one Exclusive STAR Sign NFT ).',
                source:'SNAPSHOT',
                ofAddress:'300',
                lastUpdate:'2022.09.18  10:00:00',
            },
            {
                name:'Evmos Delegator',
                minInfo:'Users who delegated EVMOS in validators.',
                type:'EVM-ADDRESS',
                created:'0x5454B3F4585D99e7d082d3b89745E2A0AA551326',
                info:'Users who delegated EVMOS in validators.',
                source:'SNAPSHOT',
                ofAddress:'100000',
                lastUpdate:'2022.09.18  10:00:00',
            },
            
        ]
    },
    nftFactory:{
        contract:'0x41B1b1ce75C1B42031E4b84f4596ddE3796B32ec'
    }
};
  