<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>

export default {
	name: 'App',
	components: {
		
	},
	data () {
		return {

		}
	},
	mounted () {

	},
	watch: {
		$route: function(to, from) {
		    window.scrollTo(0,0);
		},
	},
}
</script>


<style lang="scss">
#app{background: #000;}
</style>
