<template>
	<div class="home">
		<v-Header></v-Header>
		<div class="homeBox">
			<div class="homeBanner">
				<div class="centerBox">
					<div class="bannerBox">
						<img src="../images/home/soul.png" alt="" srcset="">
						<p>A cross-chain DID network in Cosmos and Ethereum/EVM ecosystem</p>
						<ul>
							<li>
								<img src="../images/home/dit.png" alt="">
								<span>Initially built on Evmos</span>
							</li>
							<li>
								<img src="../images/home/dit.png" alt="">
								<span>Improve Cosmos data infrastructure</span>
							</li>
							<li>
								<img src="../images/home/dit.png" alt="">
								<span>Make your DID travel freely between Cosmos and Ethereum/EVM ecosystem</span>
							</li>
						</ul>
					</div>
				</div>
			</div>
			<div class="homeData">
				<div class="centerBox">
					<div class="dataBox">
						<ul>
							<li>
								<p class="dataName">Total Users</p>
								<h3 class="dataNum">{{TotalUsers}}</h3>
							</li>
							<li>
								<p class="dataName">Total Badges Minted</p>
								<h3 class="dataNum">{{TotalBadgesMinted}}</h3>
							</li>
							<li>
								<p class="dataName">Total Stamps Created</p>
								<h3 class="dataNum">{{TotalStampsCreated}}</h3>
							</li>
							<!-- <li>
								<p class="dataName">Total Connections</p>
								<h3 class="dataNum">{{TotalConnections}}</h3>
							</li> -->
						</ul>
					</div>
				</div>
			</div>
			<div class="homeNavInfo">
				<div class="centerBox">
					<div class="infoBox">
						<ul>
							<li>
								<div class="introduce introduceBadge">
									<h1><i>Badge</i></h1>
									<p>Badge is your OAT ( on-chain achievement token ), a non-transferable NFT issued by specific Initiators. All your on-chain achievements can be recorded in different badge NFTs, part of your reputation.</p>
								</div>
								<div class="introduceImg">
									<img class="badgeImg" src="../images/home/badge.png" alt="">
								</div>
							</li>
							<li>
								<div class="introduceImg">
									<img class="stampImg" src="../images/home/stamp.png" alt="" srcset="">
								</div>
								<div class="introduce introduceStamp">
									<h1><i>Stamp</i></h1>
									<p>Stamp is the labels builded by creators to mark specific addresses collections. Creators can collect on-chain wallet behavioral data and consolidate into easily understandable Event and Property. For example, NFT whales in Evmos.</p>
								</div>
							</li>
							<li>
								<div class="introduce introduceSoulID">
									<h1><i>Soul ID</i></h1>
									<p>Soul ID is your universal profile, also a cross-chain soul bound NFT. It will integrate your on-chain and social data, traving freely in Cosmos and Ethereum/EVM ecosystem.</p>
								</div>
								<div class="introduceImg">
									<img class="soulidImg" src="../images/home/soulid.png" alt="">
								</div>
							</li>
						</ul>
					</div>
				</div>
			</div>
			<div class="homeMilestone">
				<div class="centerBox">
					<div class="milestoneBox">
						<div class="title">
							<h1>
								<span>M</span>
								<span>I</span>
								<span>L</span>
								<span>E</span>
								<span>S</span>
								<span>T</span>
								<span>O</span>
								<span>N</span>
								<span>E</span>
							</h1>
						</div>
						<div class="card">
							<div class="row">
								<div class="col">
									<div class="cardItem">
										<h3>Phase 1</h3>
										<div>
											<img src="../images/home/evmos.png" alt="">
										</div>
										<p>Initial product on Evmos</p>
										<p>Enable Badge NFT minting</p>
										<p>Some typical example Stamps</p>
									</div>
								</div>
								<div class="col">
									<div class="cardItem">
										<h3>Phase 2</h3>
										<div>
											<img src="../images/home/cosmos.png" alt="">
										</div>
										<p>Enable third-party Badge NFT</p>
										<p>Improve Soul ID data integration</p>
										<p>Collect more Cosmos ecosystem data</p>
									</div>
								</div>
								<div class="col">
									<div class="cardItem">
										<h3>Phase 3</h3>
										<div>
											<img src="../images/home/cosmos-ethereum.png" alt="">
										</div>
										<p>Enable Soul ID NFT minting</p>
										<p>Integration into more projects</p>
										<p>Integration of Cosmos and Ethereum data</p>
									</div>
								</div>
								<div class="col">
									<div class="cardItem">
										<h3>Phase 4</h3>
										<div>
											<img src="../images/home/threedit.png" alt="">
										</div>
										<p>More…</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="homePartner">
				<div class="centerBox">
					<div class="PartnerBox">
						<div class="title">
							<h1>
								<span>P</span>
								<span>A</span>
								<span>R</span>
								<span>T</span>
								<span>N</span>
								<span>E</span>
								<span>R</span>
							</h1>
						</div>
						<div class="card">
							<div>
								<a href="https://spacefi.io" target="_block"><img src="../images/home/spaceFi.png" alt=""></a>
							</div>
							<!-- <div>
								<img src="../images/home/zksync.png" alt="">
							</div>
							<div>
								<img src="../images/home/zksync.png" alt="">
							</div>
							<div>
								<img src="../images/home/zksync.png" alt="">
							</div> -->
						</div>
					</div>
				</div>
			</div>
		</div>
		<v-Footer></v-Footer>
	</div>
</template>

<script>
import { allNumWinner, allNumMinted } from '../request.js'
export default {
	name: 'Home',
	components: {
		
	},
	data () {
		return {
			TotalUsers:0,
			TotalBadgesMinted:0,
			TotalStampsCreated:0,
		}
	},
	mounted () {
		var appData = window.appData
		allNumWinner(appData.station.contract).then(res => {
			if(appData.stationCosmos.contract == ''){
				this.TotalUsers = res
			} else {
				allNumWinner(appData.stationCosmos.contract).then(resCosmos => {
					this.TotalUsers = (res-0)+(resCosmos-0)
				})
			}
			
		})
		allNumMinted(appData.station.contract).then(res => {
			if(appData.stationCosmos.contract == ''){
				this.TotalBadgesMinted = res
			} else {
				allNumMinted(appData.stationCosmos.contract).then(resCosmos => {
					this.TotalBadgesMinted = (res-0)+(resCosmos-0)
				})
			}
		})
		this.$.ajax({
			type: "GET",
			url: this.$apiUrl,
			data: {
				method:'soul.stamp.gettotalstamp',
			},
			xhrFields:{ withCredentials:true },
			dataType: "json",
			success: (data) => {
				this.TotalStampsCreated = data.data.count
			} 
		});
	},
	methods: {
		
	}
}
</script>
