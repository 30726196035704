import Vue from 'vue';
import Web3 from 'web3'
import nft from './contracts/nft.json'
import station from './contracts/station.json'
import stationCosmos from './contracts/stationCosmos.json'
import nftFactory from './contracts/nftFactory.json'
import { fromRpcSig } from 'ethereumjs-util';
import { cosmos as Cosmos} from "@cosmostation/extension-client"
import EncodeDataUtil from "./utils/EncodeDataUtill.js"
 
// Vue.prototype.$spaceWeb3 = new Web3(new Web3.providers.HttpProvider("/evmostest"))
// Vue.prototype.$spaceWeb3 = new Web3(new Web3.providers.HttpProvider("/evmos"))
Vue.prototype.$spaceWeb3 = new Web3(new Web3.providers.HttpProvider("https://eth.bd.evmos.org:8545/"))
Vue.prototype.$sendWeb3 = new Web3(window.ethereum);

if(typeof ethereum != 'undefined'){
    ethereum.on('accountsChanged', function (accounts) {
        Vue.prototype.$message.warning('Switch accounts, refreshing');
        localStorage.setItem('userAddress', accounts[0])
        setTimeout(() => {     
            window.location.reload();
        }, 1500);
    })
}
window.onload = () => {
    if(!window.ethereum && localStorage.getItem('walletType') == 'metamask'){
        localStorage.removeItem('userAddress')
    }
    if(!window.cosmostation && localStorage.getItem('walletType') == 'cosmostation'){
        localStorage.removeItem('userAddress')
        localStorage.removeItem('userAddressEvmos')
    }
    if(window.cosmostation){
        window.cosmostation.ethereum.on('accountsChanged', async function (accounts) {
            Vue.prototype.$message.warning('Switch accounts, refreshing');
            localStorage.setItem('walletType','cosmostation')
            const providerCos = await Cosmos()
			const evmosAccount = await providerCos.getAccount('cosmos')
            localStorage.setItem('userAddressEvmos',evmosAccount.address)
            localStorage.setItem('userAddress', accounts[0])
            setTimeout(() => {     
                window.location.reload();
            }, 1500);
        })
    }
}
export function judgeNetwork (){
    if(localStorage.getItem('walletType') == 'metamask'){
        var ethereum = window.ethereum
    } else {
        var ethereum = window.cosmostation.ethereum
    }
    return new Promise((resolve, reject) => {
        ethereum.request({method:'net_version'}).then(res => {
            if (res != localStorage.getItem('chainId')) {
                resolve(0)
                ethereum.request({
                    method: 'wallet_addEthereumChain',
                    params: [{
                        'chainId': Web3.utils.toHex(localStorage.getItem('chainId')),
                        'chainName': localStorage.getItem('chainName'),
                        'nativeCurrency': {
                            'name': localStorage.getItem('nativeCurrencyName'),
                            'symbol': localStorage.getItem('nativeCurrencySymbol'),
                            'decimals':18,
                        },
                        'rpcUrls': JSON.parse(localStorage.getItem('rpcUrls')),
                        'blockExplorerUrls': JSON.parse(localStorage.getItem('blockExplorerUrls')),
                    }],
                }).then((success) => {
                    // window.location.reload()
                },error => {
                    Vue.prototype.$notify({type:'warning',message:'Please connect wallet first!'})
                    // console.log(error)
                }).catch(function(err) {
                    Vue.prototype.$notify({type:'warning',message:'Please connect wallet first!'})
                    // console.log(err)
                })
                // return false
            } else {
                resolve(1)
                
            }
        });
    
    })
}

async function transactionP (from,contractAddr,methods,value){
    if(localStorage.getItem('walletType') == 'metamask'){
        Vue.prototype.$sendWeb3 = new Web3(window.ethereum);
    } else {
        Vue.prototype.$sendWeb3 = new Web3(window.cosmostation.ethereum);
    }
    return new Promise((resolve,reject) => {
        Vue.prototype.$sendWeb3.eth.getTransactionCount(from).then(beforCount => {
            var count = beforCount+1
            Vue.prototype.$sendWeb3.eth.sendTransaction({
                from: from,
                to: contractAddr,
                value: value ? Web3.utils.toHex(value) : 0,
                data: methods.encodeABI()
            }).then(res => {
                var timer = setInterval(() => {
                    Vue.prototype.$sendWeb3.eth.getTransactionCount(from).then(afterCount => {
                        if(count == afterCount) {
                            clearTimeout(timer)
                            resolve(res)
                        }
                    })
                },500)
            }).catch(err => {
                reject(err)
            })
        })
    })
}


function nftContracts(addr, rw){
    var contract;
    if ('call' == rw) {
        contract = new Vue.prototype.$spaceWeb3.eth.Contract(nft,addr)
    } else {
        contract = new Vue.prototype.$sendWeb3.eth.Contract(nft,addr)
    }
    return contract;
}
function stationContracts(addr, rw){
    var contract;
    if ('call' == rw) {
        contract = new Vue.prototype.$spaceWeb3.eth.Contract(station,addr)
    } else {
        contract = new Vue.prototype.$sendWeb3.eth.Contract(station,addr)
    }
    return contract;
}
function stationCosmosContracts(addr, rw){
    var contract;
    if ('call' == rw) {
        contract = new Vue.prototype.$spaceWeb3.eth.Contract(stationCosmos,addr)
    } else {
        contract = new Vue.prototype.$sendWeb3.eth.Contract(stationCosmos,addr)
    }
    return contract;
}
function nftFactoryContracts(addr, rw){
    var contract;
    if ('call' == rw) {
        contract = new Vue.prototype.$spaceWeb3.eth.Contract(nftFactory,addr)
    } else {
        contract = new Vue.prototype.$sendWeb3.eth.Contract(nftFactory,addr)
    }
    return contract;
}

export function allNumWinner (contractAddr,isCosmos) {
    var contract
    if(isCosmos){
        contract = stationCosmosContracts(contractAddr, 'call', '')
    } else {
        contract =  stationContracts(contractAddr, 'call','')
    }
    return new Promise((resolve, reject) => {
        contract.methods.allNumWinner().call()
        .then(res => {
            resolve(res)
        }).catch(error =>{
            reject(error)
        });
    });
};

export function allNumMinted (contractAddr,isCosmos) {
    var contract
    if(isCosmos){
        contract = stationCosmosContracts(contractAddr, 'call', '')
    } else {
        contract =  stationContracts(contractAddr, 'call','')
    }
    return new Promise((resolve, reject) => {
        contract.methods.allNumMinted().call()
        .then(res => {
            resolve(res)
        }).catch(error =>{
            reject(error)
        });
    });
};

export function Winners (contractAddr,cId,userAddr,isCosmos) {
    var contract
    if(isCosmos){
        contract = stationCosmosContracts(contractAddr, 'call', '')
    } else {
        contract =  stationContracts(contractAddr, 'call','')
    }
    return new Promise((resolve, reject) => {
        contract.methods.Winners(cId,userAddr).call()
        .then(res => {
            resolve(res)
        }).catch(error =>{
            reject(error)
        });
    });
};

export function nftInfo (contractAddr,cId,isCosmos) {
    var contract
    if(isCosmos){
        contract = stationCosmosContracts(contractAddr, 'call', '')
    } else {
        contract =  stationContracts(contractAddr, 'call','')
    }
    return new Promise((resolve, reject) => {
        contract.methods.nftInfo(cId).call()
        .then(res => {
            resolve(res)
        }).catch(error =>{
            reject(error)
        });
    });
};

export function numMinted (contractAddr,cId,isCosmos) {
    var contract
    if(isCosmos){
        contract = stationCosmosContracts(contractAddr, 'call', '')
    } else {
        contract =  stationContracts(contractAddr, 'call','')
    }
    return new Promise((resolve, reject) => {
        contract.methods.numMinted(cId).call()
        .then(res => {
            resolve(res)
        }).catch(error =>{
            reject(error)
        });
    });
};

export function claim (contractAddr,cId,nftAddr,powan,from) {
    var contract =  stationContracts(contractAddr, 'sent','')
    return new Promise((resolve, reject) => {
        transactionP(from,contractAddr,contract.methods.claim(cId,nftAddr,powan,from)).then(res => {
            resolve(res)
        }).catch(error => {
            reject(error)
        })
    });
};


function changeVal(string,num){
    var _string = ''
    for (let i = 0; i < num; i++) {
        _string+=string[Math.round(Math.random()*(0-string.length)+string.length)]
        
    }
    return _string
}
export function Tpermit (contractAddr,value,hashedData,v,r,s,cid,nftAddress,from) {
    let contract = stationCosmosContracts(contractAddr,'call','')
    return new Promise((resolve, reject) => {
        transactionP(from,contractAddr,contract.methods.permit(from,value,hashedData,v,r,s,cid,nftAddress,cid,from)).then(res => {
            resolve(res)
        }).catch(error => {
            reject(error)
        })
    });
}
export function sign (contractAddr,value,cid,nftAddress,from,msgParamsData){
    if(localStorage.getItem('walletType') == 'metamask'){
        Vue.prototype.$sendWeb3 = new Web3(window.ethereum);
    } else {
        Vue.prototype.$sendWeb3 = new Web3(window.cosmostation.ethereum);
    }
    var oldVal = value
    var _val = oldVal.slice(0,6) + changeVal(oldVal,5) + oldVal.slice(6,15) + changeVal(oldVal,8) + oldVal.slice(15,30)  + changeVal(oldVal,10) + oldVal.slice(30) 
    var _value = Vue.prototype.$sendWeb3.utils.asciiToHex(_val)
    return new Promise((resolve, reject) => {
        Vue.prototype.$sendWeb3.eth.getTransactionCount(from).then(res => {
            const msgParams = {
                domain: {
                    name: msgParamsData.name,
                    version: '1',
                    chainId: Number(localStorage.getItem('chainId')),
                    verifyingContract: contractAddr,
                },
                message: {
                    owner:from,
                    value:msgParamsData.value,
                    nonce:res
                },
                primaryType: 'Permit',
                types: {
                    EIP712Domain: [
                        { name: 'name', type: 'string' },
                        { name: 'version', type: 'string' },
                        { name: 'chainId', type: 'uint256' },
                        { name: 'verifyingContract', type: 'address' },
                    ],
                    Permit: [
                        { name: 'owner', type: 'address' },
                        { name: 'value', type: 'string' },
                        { name: 'nonce', type: 'uint256' },
                    ],
                },
            }
            const encodeUtil = new EncodeDataUtil(msgParams.types)
            const msgHash = encodeUtil.signHash(msgParams.domain, msgParams.primaryType, msgParams.message)
            var params = [from, JSON.stringify(msgParams)]
            var method = 'eth_signTypedData_v4'
            Vue.prototype.$sendWeb3.currentProvider.sendAsync({ method, params }, function (err,result) {
                if( err ){ reject(err) }
                const signature =  result.result
                const { v, r, s } = fromRpcSig(signature)
                Tpermit(msgParams.domain.verifyingContract,_value,msgHash,v,Vue.prototype.$sendWeb3.utils.bytesToHex(r),Vue.prototype.$sendWeb3.utils.bytesToHex(s),cid,nftAddress,from).then(res => {
                    resolve(res)
                }).catch(error => {
                    reject(error)
                })
                    
            })
        })
    })
}




export function allNFTIndex (contractAddr,addr) {
    let contract =  nftFactoryContracts(contractAddr, 'call','')
    return new Promise((resolve, reject) => {
        contract.methods.allNFTIndex(addr).call()
        .then(res => {
            resolve(res)
        }).catch(error =>{
            reject(error)
        });
    });
};

export function nftBalanceOf (contractAddr,from) {
    let contract =  nftContracts(contractAddr, 'call','')
    return new Promise((resolve, reject) => {
        contract.methods.balanceOf(from).call()
        .then(res => {
            resolve(res)
        }).catch(error =>{
            reject(error)
        });
    });
};

export function tokenOfOwnerByIndex (contractAddr,index,from) {
    let contract =  nftContracts(contractAddr, 'call','')
    return new Promise((resolve, reject) => {
        contract.methods.tokenOfOwnerByIndex(from,index).call()
        .then(res => {
            resolve(res)
        }).catch(error =>{
            reject(error)
        });
    });
};

export function tokenURI (contractAddr,tokenId) {
    let contract =  nftContracts(contractAddr, 'call','')
    return new Promise((resolve, reject) => {
        contract.methods.tokenURI(tokenId).call()
        .then(res => {
            resolve(res)
        }).catch(error =>{
            reject(error)
        });
    });
};

export function getNumMinted (contractAddr) {
    let contract =  nftContracts(contractAddr, 'call','')
    return new Promise((resolve, reject) => {
        contract.methods.getNumMinted().call()
        .then(res => {
            resolve(res)
        }).catch(error =>{
            reject(error)
        });
    });
};

export function ownerOf (contractAddr,tokenId) {
    let contract =  nftContracts(contractAddr, 'call','')
    return new Promise((resolve, reject) => {
        contract.methods.ownerOf(tokenId).call()
        .then(res => {
            resolve(res)
        }).catch(error =>{
            reject(error)
        });
    });
};

export function mintTime (contractAddr,tokenId) {
    let contract =  nftContracts(contractAddr, 'call','')
    return new Promise((resolve, reject) => {
        contract.methods.mintTime(tokenId).call()
        .then(res => {
            resolve(res)
        }).catch(error =>{
            reject(error)
        });
    });
};



