<template>
  <div class="footer">
    <div class="centerBox">
      <div class="footerBox">
        <div class="footerRowBox">
          <el-row>
            <el-col :xs="24" :sm="6" :md="12" :lg="12" :xl="12">
              <div class="grid-content footerLeft">
                <router-link to="/">
                  <img class="logo" src="../images/logo.png" alt="">
                </router-link>
                <p>A cross-chain DID network in Cosmos and Ethereum/EVM ecosystem.</p>
                <ul>
                  <li><a href="https://twitter.com/thesoul_network" target="_block"><img src="../images/footer/twitter.png" alt=""></a></li>
                  <li><a href="https://discord.gg/WDtuwbYRXE" target="_block"><img src="../images/footer/discord.png" alt=""></a></li>
                  <li><a href="https://www.youtube.com/channel/UCzG1q-ObM8u6nLRBBiGIgdQ" target="_block"><img src="../images/footer/youtube.png" alt=""></a></li>
                  <li><a href="https://medium.com/@soulnetwork" target="_block"><img src="../images/footer/medium.png" alt=""></a></li>
                  <li><a href="https://docs.thesoul.network" target="_block"><img src="../images/footer/docs.png" alt=""></a></li>
                </ul>
              </div>
            </el-col>
            <el-col :xs="24" :sm="6" :md="12" :lg="12" :xl="12">
              <div class="grid-content footerRight">
                <div>
                  <h3>PRODUCTS</h3>
                  <ul>
                    <li>
                      <router-link to="/badge">
                        <span>Badge</span>
                      </router-link>
                    </li>
                    <li>
                      <router-link to="/stamp">
                        <span>Stamp</span>
                      </router-link>
                    </li>
                    <li>
                      <router-link to="/soulid">
                        <span>Soul ID</span>
                      </router-link>
                    </li>
                  </ul>
                </div>
                <div>
                  <h3>COMMUNITY</h3>
                  <ul>
                    <li>
                      <a href="https://twitter.com/thesoul_network" target="_block">
                        <span>Twitter</span>
                      </a>
                    </li>
                    <li>
                      <a href="https://discord.gg/WDtuwbYRXE" target="_block">
                        <span>Discord</span>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.youtube.com/channel/UCzG1q-ObM8u6nLRBBiGIgdQ" target="_block">
                        <span>YouTube</span>
                      </a>
                    </li>
                    <li>
                      <a href="https://medium.com/@soulnetwork" target="_block">
                        <span>Medium</span>
                      </a>
                    </li>
                  </ul>
                </div>
                <div>
                  <h3>SUPPORT</h3>
                  <ul>
                    <li>
                      <a href="https://docs.thesoul.network" target="_block">
                        <span>Docs</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
export default {
  name: 'Footer',
  props: {
    msg: String
  }
}
</script>
<style lang="scss" scoped>
  .el-col {
    border-radius: 4px;
  }
  .bg-purple-dark {
    background: #99a9bf;
  }
  .bg-purple {
    background: #d3dce6;
  }
  .bg-purple-light {
    background: #e5e9f2;
  }
  .grid-content {
    border-radius: 4px;
    min-height: 80px;
  }
</style>