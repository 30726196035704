<template>
	<div class="header">
		<div class="centerBox">
			<div class="headerBox">
				<div class="headerRowBox">
					<el-row>
						<el-col :xs="3" :sm="0" :md="0" :lg="0" :xl="0">
							<div class="grid-content">
								<div class="menubox">
									<img v-if="isopen" @click="openmenu" src="../images/menu.png" alt="">
									<img v-else @click="closemenu" src="../images/menuopen.png" alt="">
								</div>
							</div>
						</el-col>
						<el-col :xs="7" :sm="3" :md="3" :lg="3" :xl="3">
							<div class="grid-content">
								<router-link to="/">
									<img class="logo" src="../images/logo.png" alt="" srcset="">
								</router-link>
							</div>
						</el-col>
						<el-col :xs="0" :sm="15" :md="15" :lg="16" :xl="17">
							<div class="grid-content homeNav">
								<!-- <router-link to="/"></router-link> -->
								<router-link to="/badge">Badge</router-link>
								<router-link to="/stamp">Stamp</router-link>
								<router-link to="/soulid">Soul ID</router-link>
							</div>
						</el-col>
						<el-col :xs="14" :sm="6" :md="6" :lg="5" :xl="4">
							<div class="grid-content headerConnectBtn" >
								<div :class="userAddress == '' ? '' : 'hoverBox'">
									<div class="top">
										<div class="networkLogo" @click="openConnectNetwork">
											<img src="../images/evmosLogo.png" alt="">
										</div>
										<div class="address">
										<!-- <div class="address" @click="openConnect"> -->
											<p v-if="userAddress != ''"><img :src="walletType == 'metamask' ? metamaskImg : cosmostationImg" alt=""> <span>{{ changeAddress(userAddress,4) }}</span> <i class="el-icon-caret-bottom"></i></p>
											<p v-else @click="openConnect">Connect <i class="el-icon-caret-right"></i></p>
										</div>
									</div>
									<div class="bottom">
										<div>
											<div class="img">
												<img v-if="userAddressEvmos != ''" src="../images/cosmostationLoge.png" alt="" srcset="">
												<img v-else src="../images/metamaskLogo.png" alt="" srcset="">
											</div>
											<div class="addr">
												<p v-if="userAddressEvmos != ''"><span>{{ changeAddress(userAddressEvmos.toLowerCase(),5) }}</span> <span @click="copy(userAddressEvmos.toLowerCase())">copy</span></p>
												<p><span>{{ changeAddress(userAddress.toLowerCase(),5) }}</span> <span @click="copy(userAddress.toLowerCase())">copy</span></p>
											</div>
										</div>
										<ul>
											<router-link tag="li" to="/soulid">Soul ID</router-link>
											<!-- <li>Soul ID</li> -->
											<li>
												<a :href="'https://escan.live/address/'+userAddress" target="_blank" rel="noopener noreferrer">View on Explorer</a>
											</li>
											<li @click="openConnectNetwork">Change Wallet</li>
											<li @click="disconnectConnect">Disconnect</li>
										</ul>
									</div>
								</div>
							</div>
						</el-col>
					</el-row>
				</div>
				<div :class=" isopen ? 'iphoneMenuBox' : 'iphoneMenuBox openMenu'">
					<div class="closeMenuBox" @click="closemenu"></div>
					<div class="menuBox">
						<router-link to="/badge" @click.native="asdf('Badge')">Badge</router-link>
						<router-link to="/stamp" @click.native="asdf('Stamp')">Stamp</router-link>
						<router-link to="/soulid" @click.native="asdf('SoulID')">Soul ID</router-link>
					</div>
				</div>
				<div class="headerConnectWallet" v-show="connectWalletShow">
					<div>
						<div class="close">
							<img src="../images/close.png" alt="" @click="closeCnnect">
						</div>
						<h1 class="title"></h1>
						<div class="wallet">
							<h2>Connect Wallet</h2>
							<!-- <h2>This mint needs to connect a IBC wallet</h2> -->
							<ul>
								<li>
									<div :class="userAddress != '' && walletType == 'metamask' ? '' : 'no'" @click="connect('metamask')">
										<img src="../images/metamaskLogo.png" alt="">
										<h2>METAMASK</h2>
										<!-- <span>(mainnet)</span> -->
									</div>
								</li>
								<li>
									<div :class="userAddress != '' && walletType == 'cosmostation' ? '' : 'no'" @click="connect('cosmostation')">
										<img src="../images/cosmostationLoge.png" alt="">
										<h2>COSMOSTATION</h2>
										<!-- <span>(mainnet)</span> -->
									</div>
								</li>
							</ul>
						</div>
					</div>
				</div>
				<div class="headerConnectWallet tipsBox" v-show="tipsShow">
					<div>
						<div class="close">
							<img src="../images/close.png" alt="" @click="closeTips">
						</div>
						<div class="tips">
							<p>{{ tipsText }}</p>
							<a class="button" :href="tipsHref" target="_blank" rel="noopener noreferrer">Install</a>
						</div>
					</div>
				</div>
				<div class="headerConnectWallet tipsBox switcBox" v-show="switchShow">
					<div>
						<div class="close">
							<img src="../images/close.png" alt="" @click="closeSwitch">
						</div>
						<div class="tips">
							<p>When money NFT belongs to cosmos type please switch to COSMOSTATION wallet first.</p>
							<div class="button" @click="switchWallet">Switch</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { cosmos, InstallError} from "@cosmostation/extension-client"
export default {
	name: 'Header',
	computed:{
		changeAddress() {
			return (val,num) => {
				var str = val
				return str.substring(0,num)+'****'+str.substring(val.length-4)
			}
		}
	},
	data() {
		return {
			metaMaskSupport:false,
			isopen:true,
			connectWalletShow:false,tipsShow:false,tipsText:'',tipsHref:'',switchShow:false,
			networkInfo:'',
			// chainId:9000,chainName:'Evmos Testnet',nativeCurrencyName:'tEVMOS',nativeCurrencySymbol:'tEVMOS',rpcUrls:['https://eth.bd.evmos.dev:8545'],blockExplorerUrls:['https://evm.evmos.dev'],
			chainId:9001,chainName:'Evmos',nativeCurrencyName:'EVMOS',nativeCurrencySymbol:'EVMOS',rpcUrls:['https://eth.bd.evmos.org:8545'],blockExplorerUrls:['https://escan.live'],
			userAddress:'',userAddressEvmos:'',
			walletType:'',isMetamask:false,
			metamaskImg:require('../images/metamaskLogo.png'),
			cosmostationImg:require('../images/cosmostationLoge.png')
		}
	},
	mounted() {
		this.userAddress = localStorage.getItem('userAddress') == 'undefined' ? '' : localStorage.getItem('userAddress') || ''
		this.userAddressEvmos = localStorage.getItem('userAddressEvmos') == 'undefined' ? '' : localStorage.getItem('userAddressEvmos') || ''
		this.metaMaskSupport = window.ethereum && window.ethereum.isMetaMask
		this.walletType = localStorage.getItem('walletType') || ''
		this.isMetamask = JSON.parse(localStorage.getItem('isMetamask')) || true
		localStorage.setItem('chainId',this.chainId)
		localStorage.setItem('chainName',this.chainName)
		localStorage.setItem('nativeCurrencyName',this.nativeCurrencyName)
		localStorage.setItem('nativeCurrencySymbol',this.nativeCurrencySymbol)
		localStorage.setItem('rpcUrls',JSON.stringify(this.rpcUrls))
		localStorage.setItem('blockExplorerUrls',JSON.stringify(this.blockExplorerUrls))

		// window.onload = () => {
		// 	this.connect(this.walletType)
		// }
	},
	methods: {
		switchWallet(){
			if (this.walletType == 'metamask') {
				this.connect('cosmostation')
			} else if (this.walletType == 'cosmostation') {
				this.connect('metamask')
			}
			this.switchShow = false
		},
		async connect(walletType) {
			this.walletType = walletType
			if (this.walletType == 'cosmostation') {
				if(window.cosmostation){
					window.cosmostation.ethereum.request({method:'eth_chainId'}).then(res => {
						if(this.$Web3.utils.hexToNumber(res) != this.chainId){
							window.cosmostation.ethereum.request({
								method: 'wallet_addEthereumChain',
								params: [{
									'chainId': this.$Web3.utils.toHex(this.chainId),
									'chainName': this.chainName,
									'nativeCurrency': {
										'name': this.nativeCurrencyName,
										'symbol': this.nativeCurrencySymbol,
										'decimals':18,
									},
									'rpcUrls': this.rpcUrls,
									'blockExplorerUrls': this.blockExplorerUrls,
								}],
							}).then((success) => {
								window.cosmostation.ethereum.request({
									method: "eth_requestAccounts",
								}).then(async res => {
									this.walletType = walletType
									const provider = await cosmos()
									const evmosAccount = await provider.getAccount(localStorage.getItem('cosmosType') || 'Evmos')
									localStorage.setItem('userAddressEvmos',evmosAccount.address)
									localStorage.setItem('walletType','cosmostation')
									localStorage.setItem('userAddress',res[0])
									window.location.reload();
								});
							}).catch(err =>{
							})
						} else {
							window.cosmostation.ethereum.request({
								method: "eth_requestAccounts",
							}).then(async res => {
								this.walletType = walletType
								const provider = await cosmos()
								const evmosAccount = await provider.getAccount(localStorage.getItem('cosmosType') || 'Evmos')
								localStorage.setItem('userAddressEvmos',evmosAccount.address)
								localStorage.setItem('walletType','cosmostation')
								localStorage.setItem('userAddress',res[0])
								window.location.reload();
							}).catch(err => {
							});
						}
					})
				} else {
					this.tipsText = 'Please install the COSMOSTATION Wallet browser extension to connect the COSMOSTATION wallet.'
					this.tipsHref = 'https://www.cosmostation.io/'
					this.walletType = localStorage.getItem('walletType')
					this.tipsShow = true
				}
				return false
			} else {
				if (!this.isMetamask) {
					this.walletType = 'cosmostation'
					return false
				} else {
					if(this.metaMaskSupport){
						ethereum.request({method:'eth_chainId'}).then(res => {
							if(this.$Web3.utils.hexToNumber(res) != this.chainId){
								ethereum.request({
									method: 'wallet_addEthereumChain',
									params: [{
										'chainId': this.$Web3.utils.toHex(this.chainId),
										'chainName': this.chainName,
										'nativeCurrency': {
											'name': this.nativeCurrencyName,
											'symbol': this.nativeCurrencySymbol,
											'decimals':18,
										},
										'rpcUrls': this.rpcUrls,
										'blockExplorerUrls': this.blockExplorerUrls,
									}],
								}).then((success) => {
									ethereum.request({
										method:'eth_requestAccounts'
									}).then(res => {
										this.walletType = walletType
										localStorage.setItem('walletType','metamask')
										this.userAddress = res[0]
										this.connectWalletShow = false
										localStorage.setItem('userAddress',res[0])
										localStorage.setItem('userAddressEvmos','')
										window.location.reload();
									})
								})
							} else {
								ethereum.request({
									method:'eth_requestAccounts'
								}).then(res => {
									this.walletType = walletType
									localStorage.setItem('walletType','metamask')
									this.userAddress = res[0]
									this.connectWalletShow = false
									localStorage.setItem('userAddress',res[0])
									localStorage.setItem('userAddressEvmos','')
									window.location.reload();
								})
							}
						})
					} else {
						this.tipsText = 'Please install the METAMASK Wallet browser extension to connect the METAMASK wallet.'
						this.tipsHref = 'https://metamask.io/'
						this.walletType = localStorage.getItem('walletType')
						this.tipsShow = true
					}
				}	
			}
		},
		openConnect() {
			if(this.userAddress != ''){
				this.userAddress = ''
				localStorage.removeItem('userAddress')
				window.location.reload();
				
			}else{
				this.connectWalletShow = true
			}
		},
		disconnectConnect() {
			this.userAddress = ''
			this.userAddressEvmos = ''
			this.walletType = ''
			localStorage.removeItem('userAddress')
			localStorage.removeItem('userAddressEvmos')
			localStorage.removeItem('walletType')
			window.location.reload()
		},
		openConnectNetwork() {
			this.connectWalletShow = true
		},
		closeCnnect() {
			this.connectWalletShow = false
		},
		closeTips() {
			this.tipsShow = false
		},
		closeSwitch() {
			this.switchShow = false
		},
		openmenu(){
			this.isopen = false
		},
		closemenu(){
			this.isopen = true
		},
		asdf(name){
			if(this.$route.name == name){
				this.isopen = true
			}
		},
		copy(value) {
			var oInput = document.createElement('input');
			oInput.value = value;
			document.body.appendChild(oInput);
			oInput.select();
			document.execCommand("Copy");
			oInput.className = 'oInput';
			oInput.style.display='none';
			this.$message({
				message:'Copy successfully',
				duration:1000,
				type:'success'
			});
		},
	}
}
</script>
